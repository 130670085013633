<template>
    <workspace-wrapper>
        <template #workspace>
            <bingo37-workspace :state-data="stateData"/>
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../../Common/WorkspaceWrapper";
import Bingo37Workspace from "./Bingo37Workspace";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    roundResult: {
                        resultNumber: null,
                        bets: [],
                        winBets: [],
                    },
                    denom: null
                }
            }
        }
    },
    components: {
        WorkspaceWrapper,
        Bingo37Workspace
    }
}
</script>

<style scoped>
.result__number {
    position: absolute;
}

.stake {
    position: absolute;
    width: 46px;
    height: 46px;
    margin-top: -23px;
    margin-left: 51px;
}

.chip {
    width: 46px;
    height: 46px;
}

.chip__text {
    font-weight: bold;
    color: white;
    cursor: help;

    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
